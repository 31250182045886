@import "core/styles";

$primaryText: var(--primaryText);
$secondaryColor: var(--secondaryColor);
$primaryColor: var(--primaryColor);

// buttons
$buttonBackground: var(--buttonBackground);
$mainBgColor: var(--mainBg);

// links
$preloaderColor: var(--preloaderColor);

// form
$colorBorderField: var(--colorBorderField);
$inputCaretColor: var(--primaryColor);

// titles
$titleH1Color: var(--titleText);
$titleH2Color: var(--titleH2Color);
$titleH3Color: var(--primaryText);
$titleH2FontSize: $fontSize22;
$questionTitleColor: var(--primaryText);
$questionSubTitleColor: var(--primaryText);

// plan
$plansInactiveBg: var(--secondaryBg);
$plansPrimaryBg: var(--secondaryBg);
$plansPrimaryTextColor: var(--primaryText);
$plansInactiveTitleColor: var(--primaryTextLight);
$plansInactiveTextColor: var(--primaryTextLight);
$plansSectionTitle: var(--primaryText);
$plansPrimaryColor: var(--primaryColor);
$plansPrimaryBorderColor: var(--primaryColor);
$plansFeatureTextColor: var(--primaryText);
$plansPrimaryTitleColor: var(--primaryColor);
$plansInactivePriceColor: var(--plansInactivePriceColor);
$plansInactiveColor: var(--plansInactiveColor);
$plansBadgeColor: var(--secondaryColor);
$plansBadgeColorLight: var(--secondaryColorLight);
$fixedButtonDiscountColor: var(--primaryColor);
$reservedPriceColor: var(--primaryColor);
$reservedPriceColorLight: var(--primaryColorLight);
$checkboxMarkCheckedBackground: var(--primaryColor);
$checkboxMarkBackground: var(--primaryColor);

// payment
$payModalPrimaryColor: var(--primaryColor);
$payModalPrimaryColorLight: var(--primaryColorLight);
$moneyBackIconColor: var(--primaryColor);
$moneyBackBackgroundColor: var(--secondaryBg);
$moneyBackTitleColor: var(--moneyBackTitleColor);

$injectedPaymentPrimaryColor: var(--primaryColor);

// success
$successTextColor: var(--primaryText);

// stars
$storeStatsStarColor: var(--primaryColor);

// feedbacks
$feedbackStarColor: var(--feedbackStarColor);

// footer
$footerTextColor: var(--primaryText);


$paymentOverlay: var(--mainBg);

// cookie modal
$cookieModalIcon: #E0E0E0;
$cookieModalTextColor: #2D2D2D;
$cookieModalBg: var(--mainBg);
$cookieModalPrimary: var(--primaryColor);
$cookieModalFootnoteText: rgba(#2D2D2D, 0.40);

$sideBarLightButtonColor: var(--primaryText);
$stepsCounterColor: var(--primaryText);
$progressBarBgColor: var(--secondaryBg);
$backArrowButtonColor: var(--primaryText);
$questionTitleBgColor: var(--secondaryBg);
$boxShadow: var(--boxShadow);
$questionUpTitleColor: var(--primaryText);
$quotesIconColor: var(--secondaryBg);
$questionAnswerSubtitleColor: var(--primaryTextLight);
$magicTextsColor: var(--primaryText);
$emailBottomPartBgColor: var(--mainBg);
$emailTitleColor: var(--primaryText);
$emailNotificationColor: var(--primaryText);
$emailInputBgColor: var(--secondaryBg);
$emailInputTextColor: var(--primaryTextLight);
$moneyBackTitleColor: var(--moneyBackTitleColor);
$feedbackBackgroundColor: var(--secondaryBg);
$feedbackTextsColor: var(--primaryText);
$footerTextColor: var(--primaryText);
$paymentSafeColor: var(--primaryText);
$policyCheckBoxTextColor: var(--primaryText);
$fixedButtonBoxBg: var(--secondaryBg);
$successTextColor: var(--primaryText);

// noteBlock
$noteBlockBgColor: #DEEBFF;