@import "src/styles";

.wrap {
  display: block;
  background: $manageCurrentPlanBg;
  margin-bottom: 32px;
  position: relative;

  &.expanded {
    .more_button {
      transform: rotate(90deg);
    }
  }

  .more_button {
    position: absolute;
    transform: rotate(270deg);
    right: 15px;
    top: 22px;
  }

  .cancel_button {
    font-family: $fontFamily;
    font-size: $fontSize15;
    font-weight: 600;
    padding: 0;
    line-height: $fontSize20;
    letter-spacing: 0px;
    margin-top: 25px;
    width: 100%;
    text-align: right;
    color: var(--primaryTextLight);
  }

  h2 {
    text-align: center;
    color: $plansPrimaryTextColor;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: $fontSize18;
  }

  .row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    text-align: center;
    border-top: $manageBorderColor solid 0.5px;
    margin-top: 12px;
    padding-top: 12px;

    .left {
      color: $plansPrimaryColor;
    }

    .right {
      color: white;
      text-align: right;
      color: $plansPrimaryTextColor;
      font-weight: 500;

      &.red {
        color: #f27067;
      }
    }
  }
}