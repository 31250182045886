@import "src/styles";

.wrapper {
  max-width: $maxWidthDefault;
  margin: -60px auto 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  height: 100%;

  .animation {
    pointer-events: none;
    position: relative;
    margin: 0 auto 10px;
    width: 275px;

    img {
      width: 100%;
      height: 100%;
    }

    video {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    font-size: $fontSize22;
    line-height: $fontSize30;
    margin-bottom: 10px;
    font-weight: 700;
    text-align: center;
    font-style: normal;
    letter-spacing: -0.6px;
    color: $mineShaft;
    margin-top: 20px;

    @media (max-height: 620px) {
      margin-bottom: 20px;
      margin-top: 0;
    }
  }

  .instructions {
    .instructions_item_text {
      font-size: $fontSize14;
      font-weight: $fwn;
      line-height: $fontSize24;
    }
  }

  .subtitle {
    font-family: $fontFamily;
    font-size: $fontSize12;
    line-height: $fontSize18;
    font-weight: 400;
    text-align: center;
    font-style: normal;
    color: $mineShaft;
    width: 80%;

    @media (max-height: 620px) {
      margin-top: -40px;
    }
  }

  .image {
    path {
      stroke: var(--primaryColor);
    }

    @media (max-height: 620px) {
      width: 125px;
      height: 167px;
      margin-top: -40px;
    }
  }

  .link {
    font-style: normal;
    font-weight: 400;
    font-size: $fontSize18;
    line-height: $fontSize24;
    letter-spacing: -0.5px;
    text-decoration: none;
    margin-top: 20px;

    &.skip {
      position: absolute;
      top: -50px;
      right: 4px;
      margin: 0;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button_fixed {
      position: absolute;
      bottom: 40px;
    }
  }
}