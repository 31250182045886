@import "src/styles";

@mixin circle {
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%)
}

.full_mode {

  .footer {
    background: transparent;
  }

  .container {
    height: 100%;

    &.transformed {
      transform: translateY(-25%);
    }

    img {
      height: calc(100% + 60px);
    }
  }
}

.wrapper {
  margin-top: -60px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  &_finished {
    justify-content: center;
  }
}

.container {
  width: 100%;
  height: 80%;
  position: relative;

  img,
  canvas {
    position: absolute;
    top: 0;
    left: 0;
  }

  canvas {
    z-index: 1;
  }

  .point {
    border-radius: 3px;
    background: white;
    position: absolute;
    width: 6px;
    height: 6px;
    animation-name: fadeInTitle;
    animation-duration: 800ms;
    animation-timing-function: ease-in;
    box-sizing: border-box;
  }

  &_finished {
    display: none;
  }
}

.photo {
  background: #d8d8d8;
  margin: auto;
}

.footer {
  width: 100%;
  flex-basis: 20%;
  flex-grow: 0;
  position: absolute;
  bottom: 0;
  z-index: 3;
  background: var(--mainBg);

  &_finished {
    background: transparent;
  }
}

.check_mark {
  display: none;
  animation: fadeInCheckMark 1s ease-in;
  position: relative;

  &_first {
    width: 95px;
    height: 95px;
    background-color: var(--secondaryBg);
    opacity: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    @include circle;

    path {
      stroke: var(--primaryColor);
    }
  }

  &_second {
    width: 125px;
    height: 125px;
    background-color: var(--primaryColor);
    opacity: 0.1;
    @include circle;
  }

  &_third {
    width: 155px;
    height: 155px;
    background-color: var(--primaryColor);
    opacity: 0.2;
    @include circle;
  }

  &_finished {
    display: flex;
  }
}

@keyframes fadeInCheckMark {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}