:root {
  --primaryColor: #6a52ff; /* Main */
  --primaryColorLight: #E0DBFF; /* Tint */
  --secondaryColor: #52dcaa; /* Accent */
  --secondaryColorLight: #52dcaa75;
  --primaryText: #2d2d2d;  /* Font */
  --mainBg: #f7f7f7;  /* Background */
  --primaryTextLight: #2d2d2d40;
  --titleText: #6a52ff;
  --secondaryText: white;
  --secondaryBg: white; /* Quetions */
  --feedbackStarColor: var(--primaryColor);
  --boxShadow: none;
  --injectedPaymentMethodActiveColor: var(--primaryColor);
  --titleH2Color: var(--primaryText);

  --buttonBackground: var(--primaryColor);
  --preloaderColor: #666666;

  --colorBorderField: #e0e0e0;
  --inputCaretColor: var(--primaryColor);

  --titleH1Color: var(--primaryColor);

  --plansPrimaryColor: var(--primaryColor);
  --plansPrimaryBorderColor: var(--primaryColor);

  --plansPrimaryTitleColor: var(--primaryColor);

  --plansInactivePriceColor: var(--primaryColor);

  --plansInactiveColor: var(--primaryColorLight);
  --plansBadgeColor: var(--secondaryColor);
  --fixedButtonDiscountColor: var(--primaryColor);

  --checkboxMarkCheckedBackground: var(--primaryColor);

  --checkboxMarkBackground: var(--primaryColor);

  --payModalPrimaryColor: var(--primaryColor);

  --moneyBackIconColor: var(--primaryColor);

  --moneyBackBackgroundColor: var(--white);
  --moneyBackTitleColor: var(--titleText);

  --successTextColor: var(--mineShaft);
  --storeStatsStarColor: var(--primaryColor);

  --feedbackStarColor: var(--primaryColor);

  --footerTextColor: var(--mineShaft);


  --paymentOverlay: #f7f7f7;
  --cookieModalIcon: #E0E0E0;
  --cookieModalTextColor: #2D2D2D;
  --cookieModalBg: var(--white);
  --cookieModalPrimary: var(--primaryColor);
  --cookieModalFootnoteText: rgba(#2D2D2D, 0.40);
}